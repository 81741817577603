import React from 'react';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { get } from 'lodash';
import InventoryUsers from '../components/InventoryUsers/InventoryUsers';
import * as actions from '../actions/inventoryUsersActionCreators';
import * as marketplaceActions from '../actions/marketplaceStatusActionCreators';
import * as bulkEditUserActions from '../actions/bulkEditItemsActionCreators';
import * as userActions from '../actions/userActionCreators';
import { fb_columns, filteredUserColumns } from '../components/UserAdmin/UserColumns';

const UserAdminQueries = gql`
  query UserAdminQueries(
    $offset: Int
    $limit: Int
    $order: String
    $orderBy: String
    $filterList: InventoryUserFilterInput
    $nameSearch: String
    $distanceSearch: String
    $distanceWithin: Int
    $tgFilter: String
    $tagFilter: String
  ) {
    getInventoryUsers(
      offset: $offset
      limit: $limit
      order: $order
      orderBy: $orderBy
      filterList: $filterList
      nameSearch: $nameSearch
      distanceSearch: $distanceSearch
      distanceWithin: $distanceWithin
      tgFilter: $tgFilter
      tagFilter: $tagFilter
      onlyUsers: true
    ) {
      id
      name
      email
      birth_date
      city
      province
      rev_yield
      clients
      total_premium
      total_assets
      key_asset
      is_invisible
      cat_plan_filename
      successor1_name
      agreement_type_for_for_catastrophic_plan
      catastrophic_plan_contract_date
      risk_of_loss
      impact_of_loss
      risk_of_loss_num
      impact_of_loss_num
      marketplace_status
      start_date
      has_user
      reason_for_loss
      manager_email
      transition_goals
      is_customer
      is_obfuscated
      profile_picture
      limited_access
      hidden
      approval_state
      omit
      heightened_supervision
      is_exception
      is_exception
      in_good_standing
      remote_id
      series_7
      series_63
      series_65
      series_66
      insurance_business
      can_match
      can_create_meeting_room
      is_student
      is_rjas
      outstanding_load_bonus_information
      alex_brown_flag
      region
      catestrophic_plan_on_file
      recognition_level
      industry_length_of_service
      amp_trainee_status
      office_city
      office_state
      office_postal_code
      discretion
      licensing
      retirement_choice_calculator_code
      pct_client_0to18
      pct_client_18to34
      pct_client_35to50
      pct_client_51to70
      pct_client_71plus
      cssid
      planning_preference
      transactional_revenue
      direct_revenue
      trailer_revenue
      fee_based_revenue
      manager_remote_id
      heightened_supervision
      team_size
      affiliation
      division
      branch
      series_7
      series_63
      series_65
      series_66
      state_registrations
      team_size
      team_name
      team_enhanced_compensation
      gross_production_2019
      gross_production_2018
      gross_production_2017
      gross_production_2016
      total_assets_2019
      total_assets_2018
      total_assets_2017
      total_assets_2016
      greater_fifty_percent_revenue_top_twenty
      fifty_percent_clients_aged_65_or_older
      bottom_two_tiers_recurring_revenue
      nna_for_the_year
      updated_at
      created_at
      minimum_profile_completed
      is_branch_exception
      market_value
      market_value_percentile
      distance_from_search(distanceSearch: $distanceSearch)
      discounted_cash_flows {
        id
        friendlyName
        name
        createdAt
        user {
          id
          name
          __typename
        }
        author {
          id
          name
          __typename
        }
      }
      company {
        id
        business_type
      }
      iu_tags {
        id
        name
        __typename
      }
      bench_count
      manager {
        id
        name
        email
        __typename
      }
      user {
        id
        annual_revenue
        recurring_revenue
        hidden
        roles
        profile_percentage_complete
        created_at
        user_name
        referral_code
        limited_access
        valuation_last_accessed_date
        business_continuity_state
        fee_based_revenue
        trailer_revenue
        direct_revenue
        transactional_revenue
        years_practicing
        number_of_clients
        assets_under_management
        last_catastrophic_plan_downloaded_date
        branch_net_payout
        has_team
        product_mix {
          id
          aum_stars
          p_c_stars
          group_stars
          health_and_life_stars
          life_ifp
          home_ifp
          auto_ifp
          commercial_ifp
          health_ifp
          group_ifp
          mutual_funds_aum
          securities_aum
          annuities_aua
          segregated_funds_aua
          total_assets
          mcpi_ifp
          private_products_ifp
          crop_hail_ifp
          crop_hail_with_wind_ifp
          farm_insurance_ifp
          price_products_ifp
          replant_supplement_ifp
          gic_fixed_income
          insurance
          equity
          __typename
        }
        __typename
      }
      __typename
    }
    getInventoryUserCount(
      offset: $offset
      limit: $limit
      order: $order
      orderBy: $orderBy
      filterList: $filterList
      nameSearch: $nameSearch
      distanceSearch: $distanceSearch
      distanceWithin: $distanceWithin
      onlyUsers: true
    )
    getCurrentUser {
      id
      company {
        id
        match_pool_exception_enabled
        business_type
        hidden_inventory_user_columns
        feature_types
      }
    }
    countryForCompany
    currentUserIsAdmin
    getUserDefaultColumnNames
    allFeatures
    getInventoryUserTags {
      id
      name
      __typename
    }
    getManagers {
      id
      email
      name
      __typename
    }
    getCurrentUser {
      company {
        id
        match_pool_exception_enabled
        business_type
        country
        affiliate_name
        add_age_column_to_tables
        catastrophic_planning_word
        __typename
      }
    }
  }
`;

const UserAdminContainer = props => {
  const { inventory_users, loadInventoryUserList, bulkEditItems } = props;
  const queryVars = {
    offset  : inventory_users.page * inventory_users.rowsPerPage || 0,
    limit   : inventory_users.rowsPerPage || 15,
    order   : inventory_users.order || 'desc',
    orderBy : inventory_users.orderBy || 'id',
  };
  return (
    <Query query={UserAdminQueries} variables={queryVars}>
      {({ loading, error, data, refetch }) => {
        if (!loading && inventory_users.loaded === false) {
          loadInventoryUserList({
            order           : queryVars.order,
            orderBy         : queryVars.orderBy,
            selected        : [],
            data            : data.getInventoryUsers,
            page            : inventory_users.page || 0,
            rowsPerPage     : queryVars.limit,
            count           : data.getInventoryUserCount,
            offset          : queryVars.offset,
            selectedColumns : data.getUserDefaultColumnNames,
            managers        : data.getManagers,
            onlyUsers       : true,
          });
        }
        return (
          <InventoryUsers
            {...props}
            company={get(data, 'getCurrentUser.company', {})}
            title="Users"
            onlyUsers
            refetch={refetch}
            fb_columns={fb_columns}
            filterColumns={filteredUserColumns}
            currentUserIsAdmin={get(data, 'currentUserIsAdmin', false)}
            loading={loading}
            tags={get(data, 'getInventoryUserTags', [])}
            allFeatures={get(data, 'allFeatures', [])}
            bulkEditItems={bulkEditItems}
          />
        );
      }}
    </Query>
  );
};

const mapStateToProps = state => ({
  inventory_user  : get(state.inventory_users, 'inventoryUser', {}),
  inventory_users : state.inventory_users,
  bulkEditItems   : state.bulkEditItems,
});

export default connect(mapStateToProps, {
  ...actions,
  ...marketplaceActions,
  ...bulkEditUserActions,
  ...userActions,
})(UserAdminContainer);
