import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import ProfilePicture from '../ProfilePicture/ProfilePicture';
import UserRatingContainer from '../../containers/UserRatingContainer';
import NotebookDrawer from '../NotebookDrawer/NotebookDrawer';
import { nameForUser, areUsersTheSame } from '../../lib/userUtils';
import UserName from './UserName';
import Location from './Location';
import Bio from './Bio';
import FinancialAttributes from './FinancialAttributes';
import Headline from '../Headline/Headline';
import { injectIntl } from 'react-intl';

const showManagedRibbon = () => (
  <figure className="ribbon" style={{ backgroundColor: '#88B001' }}>
    Managed
  </figure>
);

const showPartnerRibbon = sellerWord => (
  <figure className="ribbon" style={{ backgroundColor: '#FFD700' }}>
    {sellerWord}
  </figure>
);

const showAmpTraineeStatus = () => (
  <figure className="tag status" style={{backgroundColor: 'black'}}>
    AMP Trainee
  </figure>
);

const ProfileCard = ({
  user,
  availableStrengths,
  currentUser,
  showRolodex,
  userUpdate,
  countryForCompany,
  companyHasCounty,
  obfuscateView,
  authorized,
  enabledStudentProfiles,
  studentWord,
  searchAmpTrainee,
  newUser,
  intl,
  hideRating = false,
  companyBusinessType,
  updateBobCards,
  loadBobCardList,
  updateNotes,
  notes,
  refetch,
  isInDealPastShortlistedState,
}) => {
  const labels = {};

  (availableStrengths || []).map(s => {
    labels[s] = intl.formatMessage({ id: `sign_up_pages.${s}` });
  });
  let prov = user.province;
  prov = prov || (countryForCompany === 'ca' ? intl.formatMessage({ id: 'shared.province' }) : 'State');
  const primaryColor = companyBusinessType === 'wealth_rj' ? '#002949' : '#337ab7';
  return (
    <div className="content-card" style={{ height: 'inherit' }}>
      <div id="property-detail">
        <div className="property-title">
          <UserName
            getUser={user}
            getCurrentUser={currentUser}
            showRolodex={showRolodex}
            areUsersTheSame={areUsersTheSame}
            nameForUser={nameForUser}
            userUpdate={userUpdate}
          />
          <h1 className="headline">
            <Headline
              user={user}
              userIsAdmin={currentUser.is_admin}
              currentUserHasLimitedAccess={currentUser.limited_access}
              currentCompanySellerWord={get(currentUser, 'company.seller_only_label')}
              disableClick
              primaryColor={primaryColor}
            />
          </h1>
          <h4 className="designations">{user.designations.join(', ')}</h4>
          <h4 className="recognition-level">{get(user, 'recognition_level')}</h4>
          <span className="actions">
            {!areUsersTheSame(user, currentUser) &&
            !isInDealPastShortlistedState && (
              <NotebookDrawer
                user={user}
                updateNotes={updateNotes}
                notes={notes}
                refetch={refetch}
                updateBobCards={updateBobCards}
                loadBobCardList={loadBobCardList}
                primaryColor={primaryColor}
              />
            )}
          </span>
        </div>
      </div>
      <div id="agent-detail">
        <div id="agent-info">
          <div className="row">
            <div className="col-md-3 col-sm-3">
              <figure className="property-image agent-image">
                <div className="property-image-user-profile">
                  {currentUser.limited_access &&
                    !user.is_pure_buyer &&
                    showPartnerRibbon(get(currentUser, 'company.seller_only_label'))}
                  {user.obfuscated && showManagedRibbon()}
                  <div style={{ position: 'relative', display: 'inline-block' }}>
                    <ProfilePicture
                      pictureUrl={user.profile_picture}
                      userName={user.user_name}
                      userIsStudent={user.is_student}
                      userCompanyEnabledStudentProfiles={enabledStudentProfiles}
                      disableClic
                      userCompanyStudentWord={studentWord}
                      userHasTeam={user.has_team} />
                    {user.amp_trainee_status && searchAmpTrainee && (
                      <div style={{ position: 'absolute', top: '0', left: '0', backgroundColor: 'rgba(0, 0, 0, 0.7)', color: 'white', margin: '3px', fontSize: '12px', border: 'none' }}>
                        {showAmpTraineeStatus()}
                      </div>
                    )}
                  </div> 
                </div>
                {!areUsersTheSame(user, currentUser) &&
                !hideRating && (
                  <UserRatingContainer
                    userId={user.id}
                    score={user.my_rating}
                    user={user}
                    updateBobCards={updateBobCards}
                    loadBobCardList={loadBobCardList}
                  />
                )}
              </figure>
              <br />
            </div>
            <div className="col-md-9 col-sm-9">
              <Location
                newUser={newUser}
                city={user.city}
                province={prov}
                county={user.country}
                intl={intl}
                getUser={user}
                countryForCompany={countryForCompany}
                companyHasCounty={companyHasCounty}
              />
              <br />
              <Bio authorized={authorized} user={user} obfuscateView={obfuscateView} />
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <FinancialAttributes
              authorized={authorized}
              user={user}
              obfuscateView={obfuscateView}
              newUser={newUser}
              companyBusinessType={companyBusinessType}
            />
          </div>
        </div>
        <br />
      </div>
    </div>
  );
};

ProfileCard.propTypes = {
  user                : PropTypes.object.isRequired,
  availableStrengths  : PropTypes.array.isRequired,
  currentUser         : PropTypes.object.isRequired,
  showRolodex         : PropTypes.bool.isRequired,
  userUpdate          : PropTypes.func.isRequired,
  countryForCompany   : PropTypes.string.isRequired,
  companyHasCounty    : PropTypes.bool.isRequired,
  obfuscateView       : PropTypes.bool.isRequired,
  authorized          : PropTypes.bool.isRequired,
  enabledStudentProfiles : PropTypes.bool,
  studentWord         : PropTypes.string,
  newUser             : PropTypes.bool,
  intl                : PropTypes.object.isRequired,
  hideRating          : PropTypes.bool,
  companyBusinessType : PropTypes.string.isRequired,
  updateBobCards      : PropTypes.func,
  loadBobCardList     : PropTypes.func,
  updateNotes         : PropTypes.func,
  notes               : PropTypes.array,
  refetch             : PropTypes.func,
};

ProfileCard.defaultProps = {
  hideRating      : false,
  updateBobCards  : () => {},
  loadBobCardList : () => {},
  updateNotes     : () => {},
  refetch         : () => {},
  notes           : [],
  newUser         : false,
  enabledStudentProfiles: false,
  studentWord     : 'Student',
};

export default injectIntl(ProfileCard);
